import React, { useState } from 'react';
import { connect } from 'react-redux';

import { Button } from 'shared/components/ihcl/button';
import { Stack } from 'shared/components/ihcl/patterns';
import { Text } from 'shared/components/ihcl/text';
import { doPost } from 'shared/helpers/http';
import { TalentProfile } from 'talent/types';
import { toaster } from 'shared/components/ihcl/toast';
import {
  scheduleScreening,
  cancelScreening,
} from 'shared/actions/screeningActions';
import { LucideIcon } from 'shared/components/ihcl/lucideIcon';
import { ModalHeader } from 'shared/components/ihcl/modal';
import { getShortTimezone } from 'shared/helpers/timezones';
import { WrappedSpinner } from 'shared/components/ihcl/spinner';
import AppointmentSelection from './AppointmentSelection';
import {
  BoldText,
  ExpandingModalBody,
  FixedModalFooter,
  PrimaryCTAWrapper,
} from './styledComponents';
import { getEndDate, formattedDateValues } from './helpers';
import AppointmentDetail from './AppointmentDetail';
import Title from './Title';
import useScreeningOptions from './hooks/useScreeningOptions';

function RescheduleAppointment({
  talentProfile,
  scheduleScreeningAction,
  cancelScreeningAction,
  closeModal,
  noTimesWorkAction,
}: {
  talentProfile: TalentProfile;
  cancelScreeningAction: Function;
  scheduleScreeningAction: Function;
  closeModal: Function;
  noTimesWorkAction: Function;
}) {
  const [selectedDatetime, setSelectedDatetime] = useState(null);
  const { isLoading, availableTimes } = useScreeningOptions();
  const hasAvailableTimes = !isLoading && availableTimes.length > 0;
  const { screening } = talentProfile;
  const [isRescheduling, setIsRescheduling] = useState(false);
  const [isCanceling, setIsCanceling] = useState(false);

  const { monthDay, weekday, startTime, endTime } = formattedDateValues(
    new Date(screening.starts_at)
  );

  const handleCancel = () => {
    setIsCanceling(true);
    cancelScreeningAction(screening.id)
      .then(() => {
        toaster.info(
          <Stack $flexDirection="row" $gapSizing="scale500">
            <LucideIcon name="Check" size="unit24" />
            <div>Intro call canceled</div>
          </Stack>
        );
      })
      .then(() => closeModal('talent.screening.canceled'));
  };

  return (
    <>
      <ModalHeader>
        <Title onClick={() => closeModal('talent.screening.reschedule.close')}>
          Reschedule intro call
        </Title>
      </ModalHeader>
      <ExpandingModalBody>
        <AppointmentDetail
          label={`Time window selected (${getShortTimezone()})`}
          copy={
            <>
              <BoldText>{`${monthDay} (${weekday})`}</BoldText>{' '}
              {`${startTime} - ${endTime}`}
            </>
          }
        />
        {isLoading && <WrappedSpinner spinnerSize="large" />}
        {!isLoading && availableTimes && (
          <AppointmentSelection
            selectedDatetime={selectedDatetime}
            setSelectedDatetime={setSelectedDatetime}
            noTimesWorkAction={noTimesWorkAction}
            availableTimes={availableTimes}
          />
        )}
        {!isLoading && (
          <Stack $justifyContent="center" $alignItems="center">
            <Button
              onClick={handleCancel}
              size="default"
              kind="minimal"
              color="negative"
              // eslint-disable-next-line react/no-unstable-nested-components
              startEnhancer={() => <LucideIcon name="XCircle" size="unit16" />}
              disabled={isRescheduling}
              fullWidth
            >
              <Text
                color={isRescheduling ? 'grayLine' : 'negative'}
                variant="LabelMedium"
              >
                Cancel intro call
              </Text>
            </Button>
          </Stack>
        )}
      </ExpandingModalBody>
      {!isLoading && !hasAvailableTimes && (
        <FixedModalFooter>
          <Button
            onClick={() => closeModal('talent.screening.new.no_times')}
            size="default"
            color="alternate"
            kind="tertiary"
            fullWidth
          >
            Ok, got it
          </Button>
        </FixedModalFooter>
      )}
      {hasAvailableTimes && (
        <FixedModalFooter>
          <Button
            onClick={() => closeModal('talent.screening.reschedule.back')}
            size="default"
            color="alternate"
            kind="tertiary"
          >
            Back
          </Button>
          <PrimaryCTAWrapper>
            <Button
              onClick={() => {
                setIsRescheduling(true);
                const updates = {
                  starts_at: selectedDatetime.toISOString(),
                  ends_at: getEndDate(selectedDatetime).toISOString(),
                  phone: talentProfile.screening.phone.tel_uri,
                  email: talentProfile.screening.email,
                  notes: talentProfile.screening.notes,
                };
                doPost(
                  `/appointment_window_screenings/${screening.id}/reschedule`,
                  updates
                )
                  .then(() => scheduleScreeningAction(talentProfile.id))
                  .then(() => {
                    toaster.info(
                      <Stack $flexDirection="row" $gapSizing="scale500">
                        <LucideIcon name="Check" size="unit24" />
                        <div>Sent invite for your rescheduled intro call</div>
                      </Stack>
                    );
                  })
                  .then(() => closeModal('talent.screening.rescheduled'));
              }}
              size="default"
              color="alternate"
              kind="primary"
              isLoading={isRescheduling}
              fullWidth
              disabled={!selectedDatetime || isCanceling}
            >
              Reschedule
            </Button>
          </PrimaryCTAWrapper>
        </FixedModalFooter>
      )}
    </>
  );
}

export default connect(null, {
  scheduleScreeningAction: scheduleScreening,
  cancelScreeningAction: cancelScreening,
})(RescheduleAppointment);
